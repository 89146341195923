import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import EnrollEarlyAccess from './components/EnrollEarlyAccess/EnrollEarlyAccess';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Career from './pages/Career/Career';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import JobApplication from './pages/JobApplication/JobApplication';
import NotFound from './pages/NotFound/NotFound';
import TnC from './pages/TnC/tnc';
import PrivacyPolicy from './pages/PrivacyPolicy/privacyPolicy';

function App() {

  const [modalType, setModalType] = useState<'kyb'|'ee'>('kyb')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [openProductMenu, setOpenProductMenu] = useState<boolean>(false)
  const [blueNavbar, setBlueNavbar] = useState<boolean>(true)

  return (
    <>
      <div id='blur-bg'>
        <Header setModalOpen={setModalOpen} setModalType={setModalType} openProductMenu={openProductMenu} setOpenProductMenu={setOpenProductMenu} blueNavbar={blueNavbar} setBlueNavbar={setBlueNavbar}/> 
        <Routes>
          <Route path="/" element={
            <Home setModalOpen={setModalOpen} setModalType={setModalType}/>
          } />
          <Route path="contact" element={
            <Contact />
          } />
          <Route path="career" element={
            <Career />
          } />
          <Route path="tnc" element={
            <TnC />
          } />
          <Route path="privacyPolicy" element={
            <PrivacyPolicy />
          } />
          <Route path="career/:jobID" element={
            <JobApplication />
          } />
          <Route path="*" element={<NotFound />} />
          <Route path="career/*" element={<NotFound />} />
        </Routes>
        <Footer setOpenProductMenu={setOpenProductMenu} setBlueNavbar={setBlueNavbar} />
        <EnrollEarlyAccess type={modalType} modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </>
  );
}

export default App;
